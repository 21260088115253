document.addEventListener('turbolinks:load', function() {
  const containers = document.getElementsByClassName('box-tabs__tabs-container');

  for (let i = 0; i < containers.length; i++) {
    const tabs_container = containers[i];
    toggleArrows(tabs_container);
  }

  window.addEventListener('resize', function(event) {
    for (let i = 0; i < containers.length; i++) {
      const tabs_container = containers[i];
      toggleArrows(tabs_container);

      const container = tabs_container.parentNode;
      const arrow_right = container.getElementsByClassName('box-tabs__arrow--right')[0];
      const arrow_left = container.getElementsByClassName('box-tabs__arrow--left')[0];
      const tabs = tabs_container.getElementsByClassName('box-tabs__tabs')[0];

      const currentFirstTab = tabs.getElementsByClassName('firstTab')[0];
      currentFirstTab.classList.remove('firstTab');
      tabs.children[0].classList.add('firstTab');
      tabs.style.marginLeft = '0px';
      disableArrows(tabs_container, tabs, arrow_right, arrow_left);
    }
  });

  $('.box-tab').on('click', function(e) {
    getActiveTabStyle(e.target);
  });
});

const getActiveTabStyle = function (e) {
  const parent = e.parentNode;

  if (parent.classList.contains('box-tabs__tabs')) {
    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i] != e) {
        parent.children[i].classList.remove('box-tab--active');
      } else if (!e.classList.contains('box-tab--active')) {
        e.classList.add('box-tab--active');
      }
    }
  } else {
    getActiveTabStyle(parent, e);
  }
};

const toggleArrows = function(tabs_container) {
  const tabs = tabs_container.getElementsByClassName('box-tabs__tabs')[0];
  const arrows = tabs_container.parentNode.getElementsByClassName('box-tabs__arrow');

  for (let i = 0; i < arrows.length; i++) {
    const arrow = arrows[i];
    if (tabs_container.offsetWidth >= tabs.offsetWidth) {
      arrow.classList.add('d-none');
      arrow.classList.remove('d-block');
      tabs_container.classList.add('noArrows');
    } else {
      arrow.classList.remove('d-none');
      arrow.classList.add('d-block');
      tabs_container.classList.remove('noArrows');
    }
  }
};

const disableArrows = function(tabs_container, tabs, arrow_right, arrow_left) {
  const tabs_container_width = tabs_container.offsetWidth;
  const tabs_width = tabs.offsetWidth;
  const tabs_margin_left = parseInt(window.getComputedStyle(tabs).marginLeft, 10);
  const max_margin_left = tabs_container_width - tabs_width;

  if (tabs_margin_left < 0) {
    arrow_left.classList.remove('box-tabs__arrow--disabled');
  } else {
    if (!arrow_left.classList.contains('box-tabs__arrow--disabled')) {
      arrow_left.classList.add('box-tabs__arrow--disabled');
    }
  }

  if (tabs_margin_left > max_margin_left) {
    arrow_right.classList.remove('box-tabs__arrow--disabled');
  } else {
    if (!arrow_right.classList.contains('box-tabs__arrow--disabled')) {
      arrow_right.classList.add('box-tabs__arrow--disabled');
    }
  }
};

const scrollTabs = function(e) {
  const container = e.parentNode;

  const arrow_right = container.getElementsByClassName('box-tabs__arrow--right')[0];
  const arrow_left = container.getElementsByClassName('box-tabs__arrow--left')[0];

  const tabs_container = container.getElementsByClassName('box-tabs__tabs-container')[0];
  const tabs = tabs_container.getElementsByClassName('box-tabs__tabs')[0];
  let tabs_margin_left = parseInt(window.getComputedStyle(tabs).marginLeft, 10);

  let firstTabWidth = 0;
  const currentFirstTab = tabs.getElementsByClassName('firstTab')[0];

  if (e == arrow_right) {
    const nextTab = currentFirstTab.nextElementSibling;
    if (nextTab != null) {
      nextTab.classList.add('firstTab');
      currentFirstTab.classList.remove('firstTab');
      firstTabWidth = currentFirstTab.offsetWidth;
    }

    tabs_margin_left = tabs_margin_left - firstTabWidth;
    const max_margin_left = tabs_container.offsetWidth - tabs.offsetWidth;
    if (tabs_margin_left < max_margin_left && nextTab.nextElementSibling == null) {
      tabs_margin_left = max_margin_left;
    }
  } else if (e == arrow_left) {
    const previousTab = currentFirstTab.previousElementSibling;
    if (previousTab != null) {
      previousTab.classList.add('firstTab');
      currentFirstTab.classList.remove('firstTab');
      firstTabWidth = currentFirstTab.offsetWidth;
    }

    tabs_margin_left = tabs_margin_left + firstTabWidth;
    if (tabs_margin_left > -previousTab.offsetWidth || previousTab.previousElementSibling == null) {
      tabs_margin_left = 0;
    }
  }

  tabs.style.marginLeft = tabs_margin_left + 'px';

  disableArrows(tabs_container, tabs, arrow_right, arrow_left);
};

global.scrollTabs = scrollTabs;
window.scrollTabs = scrollTabs;
