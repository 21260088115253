const updateNavLinkArrow = function(e) {
  e.getElementsByClassName('nav-link__arrow')[0].classList.toggle('fa-angle-down');
  e.getElementsByClassName('nav-link__arrow')[0].classList.toggle('fa-angle-left');
};

global.updateNavLinkArrow = updateNavLinkArrow;
window.updateNavLinkArrow = updateNavLinkArrow;

const toggleMenu = function() {
  const breakpoint = 768; // 768 -- bootstrap md breakpoint
  const navbar = document.getElementById('navbar');
  const layout = document.getElementById('layout');

  if (window.innerWidth < breakpoint) {
    navbar.classList.toggle('collapsed');
    navbar.classList.toggle('uncollapsed');

    if (navbar.classList.contains('collapsed-md')) {
      navbar.classList.remove('collapsed-md');
      navbar.classList.add('collapsed-md');
    } else {
      navbar.classList.remove('uncollapsed-md');
      navbar.classList.add('uncollapsed-md');
    }
  } else {
    if (!navbar.classList.contains('collapsed')) {
      navbar.classList.add('collapsed');
      navbar.classList.remove('uncollapsed');
    }
    navbar.classList.toggle('collapsed-md');
    navbar.classList.toggle('uncollapsed-md');
    layout.classList.toggle('to-right');
  }

  const elements = navbar.getElementsByClassName('navbar-collapse');

  for (let i = 0; i < elements.length; i++) {
    if (window.innerWidth < breakpoint) {
      if (elements[i].classList.contains('nav-brand')) {
        elements[i].classList.add('navbar--collapsed');
        elements[i].classList.remove('navbar--uncollapsed');
      } else {
        elements[i].classList.toggle('navbar--collapsed');
        elements[i].classList.toggle('navbar--uncollapsed');
      }

      if (elements[i].classList.contains('navbar--collapsed-md')) {
        elements[i].classList.remove('navbar--collapsed-md');
        elements[i].classList.add('navbar--collapsed-md');
      } else {
        elements[i].classList.remove('navbar--uncollapsed-md');
        elements[i].classList.add('navbar--uncollapsed-md');
      }
    } else {
      if (!elements[i].classList.contains('navbar--collapsed')) {
        elements[i].classList.add('navbar--collapsed');
        elements[i].classList.remove('navbar--uncollapsed');
      }
      elements[i].classList.toggle('navbar--collapsed-md');
      elements[i].classList.toggle('navbar--uncollapsed-md');
    }
  }
};

global.toggleMenu = toggleMenu;
window.toggleMenu = toggleMenu;

// on resize
let lastWindowWidth = window.innerWidth;
window.addEventListener('resize', function(ev) {
  // nav is closed only on horizontal resize
  if (lastWindowWidth != window.innerWidth) {
    lastWindowWidth = window.innerWidth;
    const breakpoint = 768; // 768 -- bootstrap md breakpoint
    const nav = document.getElementById('navbar');
    const elements = nav.getElementsByClassName('navbar-collapse');
    const layout = document.getElementById('layout');

    if (window.innerWidth < breakpoint) {
      nav.classList.add('collapsed');
      nav.classList.remove('uncollapsed');

      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add('navbar--collapsed');
        elements[i].classList.remove('navbar--uncollapsed');
      }

      if (layout.classList.contains('to-right')) {
        layout.classList.remove('to-right');
      }
    } else {
      if (nav.classList.contains('collapsed-md') && nav.classList.contains('uncollapsed')) {
        nav.classList.add('collapsed');
        nav.classList.remove('uncollapsed');

        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('navbar--collapsed');
          elements[i].classList.remove('navbar--uncollapsed');
        }
      }

      if (elements[0].classList.contains('navbar--collapsed-md') && !layout.classList.contains('to-right')) {
        layout.classList.add('to-right');
      }
    }
  }
});

// show sidebar active class on page refresh
document.addEventListener('turbolinks:load', function() {
  const activeItem = $('.nav-item .active').offset().top;
  const scrollHeight = $('#navbar-content').outerHeight();

  // console.log("POSIÇÃO ACTIVE: " + activeItem);
  // console.log("NAVBAR HEIGHT: " + scrollHeight);

  if (activeItem > scrollHeight) {
    $('#navbar-content').animate({
      scrollTop: activeItem - 150
    }, 0);
  }
});
