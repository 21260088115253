document.addEventListener('turbolinks:load', function() {
  const itemsTF = document.querySelectorAll('.box-filters__showMore');
  const btnTF = document.querySelectorAll('.box-filters__showMore-btn');

  const elementsTF = [];
  itemsTF.forEach(function(el) {
    elementsTF.push(el);
  });
  btnTF.forEach(function(el) {
    el = el.querySelectorAll('.box-filters__showMore-txt')[1];
    elementsTF.push(el);
  });

  elementsTF.forEach(function(el) {
    el.classList.add('d-none');
  });
});

const toggleFilters = function(btn) {
  const content = btn.parentNode;

  if (content.classList.contains('box-filters__content')) {
    const elementsTF = content.querySelectorAll('.box-filters__showMore, .box-filters__showMore-txt');

    elementsTF.forEach(function(el) {
      if (el.classList.contains('d-none')) {
        el.classList.remove('d-none');
      } else {
        el.classList.add('d-none');
      }
    });
  } else {
    toggleFilters(content);
  }
};

global.toggleFilters = toggleFilters;
window.toggleFilters = toggleFilters;
