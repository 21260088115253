document.addEventListener('turbolinks:load', function() {
  $('.editable-icon').addClass('d-block');

  const toggleEdit = (el, sibling_class) => {
    $(el).removeClass('d-block').addClass('d-none');
    $(el).siblings(sibling_class).removeClass('d-none').addClass('d-block');
  };

  global.toggleEdit = toggleEdit;
  window.toggleEdit = toggleEdit;
});
