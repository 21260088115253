import Rails from '@rails/ujs';

const submitOnChange = () => {
  // submit_on_change
  document.querySelectorAll('.submit_on_change').forEach(function(el) {
    el.addEventListener('change', function(e) {
      const parent = e.target.parentNode;
      submitIfForm(parent);
    });
  });
};
global.submitOnChange = submitOnChange;
window.submitOnChange = submitOnChange;

const submitIfForm = (el) => {
  const form = el.closest('form');

  if (form.tagName === 'FORM') {
    if (form.dataset.remote == 'true') {
      Rails.fire(form, 'submit');
    } else {
      form.submit();
    }
  }
};
global.submitIfForm = submitIfForm;
window.submitIfForm = submitIfForm;

document.addEventListener('turbolinks:load', function() {
  submitOnChange();
});
