// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application';
import '../hyped_listing';

import 'vendor/nested_form_fields';
import 'vendor/daterangepicker';
import 'vendor/rails_sortable';
import 'vendor/jquery-loading';
import 'vendor/jquery.confirm.min';

import 'general/simplemde';
import 'general/box-filters';
import 'general/box-tabs';
import 'general/sidebar';
import 'general/submit_on_change';
import 'general/toasts';
import 'general/select';
import 'general/editable_icon';
import 'general/tooltip';
import 'general/form-wizard';
import 'general/search';
import 'general/init';
import 'general/remove_newsletter_section';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

const jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('jquery-ui');
require('bootstrap');
require('select2');

const moment = require('moment');
global.moment = moment;
window.moment = moment;

require('moment-timezone');
require('pc-bootstrap4-datetimepicker');
require('chartkick');
require('chart.js');

const clipboard = require('clipboard');
global.ClipboardJS = clipboard;
window.ClipboardJS = clipboard;

const autonumeric = require('autonumeric');
global.AutoNumeric = autonumeric;
window.AutoNumeric = autonumeric;

const simplemde = require('simplemde');
global.SimpleMDE = simplemde;
window.SimpleMDE = simplemde;

require('trix');
require('@rails/actiontext');

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});
