document.addEventListener('turbolinks:load', function() {
  $('select').not('.select2-hidden-accessible').each(function() {
    $(this).wrap('<span class=\'select-container\'></span>');
  });

  $(document).on('select2:open', () => {
    setTimeout(function() {
      const input = document.querySelector('input.select2-search__field');
      if (input) {
        input.focus();
      }
      // const textarea = document.querySelector('textarea.select2-search__field');
      // if (textarea) {
      //   textarea.focus();
      // }
    }, 100);
  });
});
