document.addEventListener('turbolinks:load', function() {
  const startToast = function() {
    const toast = $('.toast:not(.show)');

    toast.toast({ delay: 5000 });
    toast.toast('show');

    toast.on('hidden.bs.toast', function() {
      $(this).remove();
    });
  };

  startToast();

  global.startToast = startToast;
  window.startToast = startToast;
});
