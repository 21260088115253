import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function() {
  const hypedListingSetup = function(updated_resource_name) {
    // Add events to sort columns
    let sortLinks;
    if (updated_resource_name != undefined) {
      sortLinks = document.querySelectorAll('[data-hyped-listing-sort-link-resource="' + updated_resource_name + '"]');
    } else {
      sortLinks = document.querySelectorAll('[data-hyped-listing-sort-link]');
    }

    sortLinks.forEach(element => {
      if (!element.getAttribute('is-hyped-up')) {
        element.setAttribute('is-hyped-up', true);

        element.addEventListener('click', function (event) {
          // Don't follow the link
          event.preventDefault();

          // Log the clicked element in the console
          // console.log(event.currentTarget);
          const resource = event.currentTarget.dataset.hypedListingSortLinkResource;
          const form = document.querySelector('[data-hyped-listing-form-resource="' + resource + '"]');

          const orderByHiddenField = form.querySelector('.hyped-listing-param-order-by');
          const orderHiddenField = form.querySelector('.hyped-listing-param-order');
          orderByHiddenField.value = event.currentTarget.dataset.hypedListingSortLink;

          if (orderHiddenField.value == 'asc') {
            orderHiddenField.value = 'desc';
          } else {
            orderHiddenField.value = 'asc';
          }

          if (form.dataset.remote == 'true') {
            Rails.fire(form, 'submit');
          } else {
            form.submit();
          }
        }, { once: true });
      }
    });

    // Add events to choose how many items will be shown
    const perPageLinks = document.querySelectorAll('[data-hyped-listing-per-page-link]');

    perPageLinks.forEach(element => {
      element.addEventListener('click', function (event) {
        // Don't follow the link
        event.preventDefault();

        // Log the clicked element in the console
        // console.log(event.currentTarget);
        const resource = event.currentTarget.dataset.hypedListingPerPageLinkResource;
        const perPage = event.currentTarget.dataset.hypedListingPerPageLink;
        const form = document.querySelector('[data-hyped-listing-form-resource="' + resource + '"]');

        const perPageHiddenField = form.querySelector('.hyped-listing-param-per-page');
        perPageHiddenField.value = perPage;

        if (form.dataset.remote == 'true') {
          Rails.fire(form, 'submit');
        } else {
          form.submit();
        }
      }, { once: true });
    });

    // Remove duplicates hidden fields
    const forms = document.querySelectorAll('[data-hyped-listing-form-resource]');

    forms.forEach(form => {
      const otherParams = form.querySelectorAll('.hyped-listing-other-param');

      otherParams.forEach(otherParam => {
        const inputs = form.querySelectorAll('[name="' + otherParam.getAttribute('name') + '"]');

        if (inputs.length > 1) {
          otherParam.parentNode.removeChild(otherParam);
        }
      });

      // LOADING
      const style = document.createElement('style');
      style.innerHTML = '.show-load { opacity 1; transition: opacity 500ms; } .hide-load { opacity: 0.2; transition: opacity 500ms; }';

      const ref = document.querySelector('script');
      ref.parentNode.insertBefore(style, ref);

      form.addEventListener('ajax:before', function (event) {
        const resource = event.currentTarget.dataset.hypedListingFormResource;
        const content = document.querySelector('[data-hyped-listing-content-resource="' + resource + '"]');

        content.parentNode.classList.add('hide-load');
        content.parentNode.classList.remove('show-load');
      }, { once: true });

      form.addEventListener('ajax:success', function (event) {
        const resource = event.currentTarget.dataset.hypedListingFormResource;
        const content = document.querySelector('[data-hyped-listing-content-resource="' + resource + '"]');

        content.parentNode.classList.add('show-load');
        content.parentNode.classList.remove('hide-load');
      }, { once: true });
    });
  };

  hypedListingSetup();
  window.hypedListingSetup = hypedListingSetup;

  // refresh the given resource or first hyped listing form
  const hypedListingRefresh = function() {
    const forms = document.querySelectorAll('[data-hyped-listing-form-resource]');

    forms.forEach(form => {
      if (form.dataset.remote == 'true') {
        Rails.fire(form, 'submit');
      } else {
        form.submit();
      }
    });
  };

  window.hypedListingRefresh = hypedListingRefresh;
});
