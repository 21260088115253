const clearSelect2 = function(div_id) {
  $('#' + div_id).html('<option></option>').trigger('change');
  submitIfForm($('#' + div_id)['0']);
};

global.clearSelect2 = clearSelect2;
window.clearSelect2 = clearSelect2;

const clearSelect2NoSubmit = function(div_id) {
  $('#' + div_id).html('<option></option>').trigger('change');
};

global.clearSelect2NoSubmit = clearSelect2NoSubmit;
window.clearSelect2NoSubmit = clearSelect2NoSubmit;

const clearDate = function(div_id) {
  $('#' + div_id).val('').trigger('change');
  submitIfForm($('#' + div_id)['0']);
};

global.clearDate = clearDate;
window.clearDate = clearDate;

$(document).on('page:load turbolinks:load', function() {
  $('.searchable').each(function() {
    const $this = $(this);
    $this.select2({
      ajax: {
        url: $this.data('searchPath'),
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            search: params.term,
            page: params.page
          };
        },
        processResults: function (data, params) {
          return {
            results: $.map(data.users, function(object, i) {
              return { id: object.id, text: object.name + ' - ' + object.email };
            })
          };
        },
        cache: true
      },
      minimumInputLength: 2,
      escapeMarkup: function (markup) { return markup; },
      theme: 'bootstrap4',
      placeholder: $this.attr('placeholder')
    });
  });
});
