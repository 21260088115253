document.addEventListener('turbolinks:load', function() {
  const setRemoveNewsletterSection = function() {
    document.querySelectorAll('.remove-newsletter-section').forEach(btn => {
      btn.addEventListener('click', () => {
        btn.closest('.newsletter-section').remove();
      });
    });
  };

  setRemoveNewsletterSection();

  global.setRemoveNewsletterSection = setRemoveNewsletterSection;
  window.setRemoveNewsletterSection = setRemoveNewsletterSection;

  document.querySelectorAll('.toggle-btn-template-form').forEach(btn => {
    btn.addEventListener('click', () => {
      const template_form = document.querySelector('#template-form');
      if (template_form.style.display === 'none') {
        template_form.style.display = 'block';
      } else {
        template_form.style.display = 'none';
      }
    });
  });
});
