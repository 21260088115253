document.addEventListener('turbolinks:load', function() {
  const textareas_simplemde = document.getElementsByClassName('textarea_simplemde');
  const simplemdes = [];

  Array.prototype.forEach.call(textareas_simplemde, function(element, index, array) {
    simplemdes[index] = new SimpleMDE({
      // element: document.getElementById("textarea_simplemde") ,
      element: element,
      spellChecker: false,
      showIcons: ['strikethrough', 'heading-1', 'heading-2', 'heading-3', 'code', 'table', 'horizontal-rule']
      // toolbar: ['bold', 'italic', 'strikethrough', 'heading-1', 'heading-2', 'heading-3', '|', 'code', 'quote', 'unordered-list', 'ordered-list', '|', 'link', 'image', 'table', 'horizontal-rule', '|', 'preview', 'side-by-side', 'fullscreen', '|', 'guide'],
    });
  });
});
