const moment = require('moment');

moment.updateLocale('en', {
  week: { dow: 1 } // Monday is the first day of the week
});

const setupAcceptInvitationUrlListeners = function() {
  // eslint-disable-next-line no-new
  new ClipboardJS('.clipboard-btn');

  $('.fetch-accept-invitation-url').on('click', function (event) {
    event.preventDefault();
    const el = $(this)[0];
    setAcceptInvitationUrlInput($(el).data('user-id'), $(el).data('user-kind'), el);
  });
};

const setupResetPasswordUrlListeners = function() {
  // eslint-disable-next-line no-new
  new ClipboardJS('.clipboard-btn');

  $('.fetch-reset-password-url').on('click', function (event) {
    event.preventDefault();
    const el = $(this)[0];
    setResetPasswordUrlInput($(el).data('user-id'), $(el).data('user-kind'), el);
  });
};

const setAcceptInvitationUrlInput = function(user_id, user_kind, el) {
  $.ajax({
    url: '/user/users/' + user_id + '/' + user_kind + '_accept_invitation_url',
    method: 'POST',
    success: function(response) {
      $('#input--accept-invitation-url-' + user_id).val(response.accept_path);
    }
  });
};

const setResetPasswordUrlInput = function(user_id, user_kind, el) {
  $.ajax({
    url: '/user/users/' + user_id + '/' + user_kind + '_reset_password_url',
    method: 'POST',
    success: function(response) {
      $('#input--reset-password-url-' + user_id).val(response.accept_path);
    }
  });
};

$(document).on('turbolinks:load', function () {
  setupAcceptInvitationUrlListeners();
  setupResetPasswordUrlListeners();

  // apply select2
  $('.form-select2').select2({
    theme: 'bootstrap4'
  });

  $(document).on('fields_added.nested_form_fields', function(event, param) {
    $(event.target).find('.form-select2').select2({
      theme: 'bootstrap4'
    });
  });
});
